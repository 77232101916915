<template>
  <v-container>
   {{text}}
   <v-btn block @click="ubahTeks"> Button Block </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    text: "Hello World!"
  }),
  methods:{
    ubahTeks(){
      this.text="0620104010 - Ary Syaddam"
    }
  },
  beforeMount(){
    this.text = "Hallo Ary Syaddam"
  }
};
</script>
