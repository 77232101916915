import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Hal2 from "../views/Hal2.vue";
import NamaLengkap from "../views/NamaLengkap.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/hal1",
    name: "Hal1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Hal1.vue"),
  },
  {
    path: "/hal2",
    name: "Hal2",
    component: Hal2,
  },
  {
    path: "/namalengkap",
    name: "NamaLengkap",
    component: NamaLengkap,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
