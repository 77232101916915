
<template>
  <v-container>
      <h2>0620104010 - Ary Syaddam.</h2>
      <v-text-field v-model="namaDepan" label="First Name" filled></v-text-field>
      <v-text-field v-model="namaBelakang" label="Last Name" filled></v-text-field>

      <div v-if="FullName==='Ary Syaddam'">
        <div v-for="biodataSaya in biodata" v-bind:key="biodataSaya.id">
          <ItemBiodata v-bind:biodataSaya="biodataSaya" />
        </div>
      </div>
      <div v-else>
        <h2>Nama belum sesuai 😢</h2>
      </div>
      <br><v-btn @click="ClearText"> Clear Text </v-btn>
  </v-container>
</template>

<script>
import ItemBiodata from "./ItemBiodata.vue";

export default {

  name: "Nama Lengkap",

  data : () => ({
    namaDepan : "",
    namaBelakang : "",
    awesome : true,
        biodata: [
          {
            id: 1,
            namaParam: "Nama : Ary Syaddam"
          },
          {
            id: 2,
            namaParam: "NPM : 0620104010"
          },
          {
            id: 3,
            namaParam: "Kelas : T. Informatika Reguler B2-A"
          }
        ]
  }),

  components: {
    ItemBiodata,
  },

  computed : {
    FullName : function () {
        return this.namaDepan + " " + this.namaBelakang
    }
  },

  methods : {
    ClearText(){
      this.namaDepan = "",
      this.namaBelakang = ""
    }
  },
    
  beforeMount(){
    alert("Hello...")
  }
};
</script>
