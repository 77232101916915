<template>
    <div>
        <h2>{{biodataSaya.namaParam}}</h2>
    </div>
</template>

<script>
export default ({
    name: "ItemBiodata",
    props: ["biodataSaya"]
})
</script>
