<template>
  <nama-lengkap />
</template>

<script>
import NamaLengkap from '../components/NamaLengkap';

export default {
  name: "Nama Lengkap",

  components: {
    NamaLengkap,
  },
};
</script>
